<template>
  <div>
    <el-card>
      <all-log :dialogLog="dialogLog" />

      <div class="menu-box">
        <h2>站点管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-button type="primary" @click="pAdd()">添加站点</el-button>
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
          >
            <el-table-column prop="site_name" label="站点名"> </el-table-column>
            <el-table-column prop="site_type_name" label="站点类型名">
            </el-table-column>
            <el-table-column prop="site_address" label="站点地址">
            </el-table-column>
            <el-table-column label="站点图片">
              <template v-slot="scope">
                <img :src="scope.row.site_cover_image" width="100" alt="" />
              </template>
            </el-table-column>
            <el-table-column prop="site_status" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.site_status === 1">
                  启用
                </el-tag>
                <el-tag type="warning" v-if="scope.row.site_status === 0">
                  禁用
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :pager-count="15"
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.limit"
            background
            :total="exp.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 添加/删除对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="formClose"
    >
      <el-form :rules="formRules" ref="formRef" :model="form">
        <el-form-item
          prop="site_name"
          label="站点名称"
          :label-width="formLabelWidth"
        >
          <el-input
            @change="handleChange"
            v-model="form.site_name"
            placeholder="请填写站点名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="site_address"
          label="站点地址"
          :label-width="formLabelWidth"
        >
          <el-input
            @change="handleChange"
            v-model="form.site_address"
            placeholder="请填写站点地址"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="site_type"
          label="站点类型"
          :label-width="formLabelWidth"
        >
          <el-select
            v-model="form.site_type"
            style="width: 100%"
            placeholder="请选择站点类型"
            clearable
            @change="handleChange"
          >
            <el-option
              v-for="item in siteTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          prop="site_cover_image"
          label="站点图片"
          :label-width="formLabelWidth"
        >
          <el-upload
            :headers="token"
            accept=".jpg,.png,.jpeg"
            :on-remove="imgRemove"
            :limit="1"
            :action="imgUrl"
            list-type="picture"
            :on-exceed="exceed"
            :on-success="imgSuccess"
            name="image"
            :file-list="fileList"
            :on-change="handleChange"
          >
            <el-button size="small" type="primary">上传站点图片</el-button>
          </el-upload>
        </el-form-item>

        <!-- <el-form-item
              v-if="addOrEdit === 2"
              prop="sort"
              label="排序"
              :label-width="formLabelWidth"
            >
              <el-input @change="handleChange" v-model="form.sort"></el-input>
            </el-form-item> -->

        <el-form-item
          v-if="addOrEdit === 2"
          prop="site_status"
          label="状态"
          :label-width="formLabelWidth"
        >
          <el-switch
            @change="handleChange"
            v-model="form.site_status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button type="primary" @click="confirmAE()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSiteList,
  addSite,
  getInfo,
  editSiteType,
  delSiteType,
} from '@/api/edu/site.js'

export default {
  data() {
    return {
      dialogLog: {
        state: false,
      },
      token: {
        'Accept-Token': window.sessionStorage.getItem('token'),
      },
      isEdit: false,
      tableLoading: false,
      warningText: '',
      exp: {
        total: 0,
      },
      siteTypeList: [
        { id: 1, name: '餐厅' },
        { id: 2, name: '酒店' },
        { id: 3, name: '房车' },
        { id: 4, name: '停车场' },
        { id: 5, name: '入口' },
        { id: 6, name: '物料点' },
        { id: 7, name: '民宿' },
      ],
      spcateList: [],
      serviceList: [],
      query: {
        page: 1,
        page_code: '',
        page_num: 7,
      },
      optionProps: {
        checkStrictly: false,
        value: 'id',
        label: 'cname',
        children: 'sub',
      },
      dialogVisible: false,
      changeDialogVisible: false,
      formRules: {
        site_name: {
          required: true,
          message: '请输入站点名称',
          trigger: 'blur',
        },
        // sort: { required: true, message: '请输入物料列表排序' },
        site_address: {
          required: true,
          message: '请输入站点地址',
          trigger: 'blur',
        },
        site_cover_image: {
          required: true,
          message: '请上传站点图片',
          trigger: 'blur',
        },
        site_type: {
          required: true,
          message: '请选择站点类型',
          trigger: 'blur',
        },
      },
      title: '添加服务地点',
      addOrEdit: 1,
      formLabelWidth: '120px',
      optionsTask: [],
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      fileList: [],
      form: {
        site_type: '',
        site_name: '',
        site_cover_image: '',
        regional_id: 1,
        site_address: '',
      },
      tableData: [],
      provList: [],
    }
  },
  created() {
    this.getList()
  },
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl
    },
  },
  methods: {
    exceed() {
      this.$message({
        message: '只能上传一张图片',
        type: 'warning',
      })
    },
    imgSuccess(response, file, fileList) {
      this.form.site_cover_image = response.data.url
    },
    imgRemove() {
      this.fileList = []
      this.form.site_cover_image = ''
    },
    async getOptionList() {
      const { data: res } = await provinceList()
      console.log(res)
      if (res.code === 1) {
        this.provList = res.data
      } else {
        this.$message.error('获取省市区数据失败！')
      }
    },
    pageChange(val) {
      this.query.page = val
      this.getList()
    },
    sizeChange(val) {
      this.query.page_num = val
      this.getList()
    },
    async getList() {
      this.tableLoading = true
      const { data: res } = await getSiteList(this.query)
      console.log(res)
      if (res.code === 1) {
        this.tableData = res.data.data
        this.exp.total = res.data.total
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancelAE() {
      this.title = '添加站点'
      this.addOrEdit = 1
      this.isEdit = false
      this.form = {
        site_type: '',
        site_name: '',
        site_cover_image: '',
        regional_id: 1,
        site_address: '',
      }
      this.fileList = []
      if (this.$refs.formRef) {
        setTimeout(() => {
          this.$refs.formRef.clearValidate()
        }, 30)
      }

      this.dialogVisible = false
    },

    // 新增打开
    pAdd(row) {
      console.log(row)
      //this.getOptionList()
      this.dialogVisible = true
    },
    // 编辑打开
    async handleEdit(row) {
      this.dialogVisible = true
      this.title = '编辑站点'
      this.addOrEdit = 2
      const { data: res } = await getInfo({
        site_id: row.site_id,
      })
      this.form = res.data
      this.form.regional_id = 1
      this.fileList.push({ url: res.data.site_cover_image })
    },

    // 删除
    remove(row) {
      this.$confirm('确认删除？')
        .then(async (_) => {
          const { data: res } = await delSiteType({
            site_id: row.site_id,
          })
          if (res.code === 1) {
            this.$message.success('删除成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    confirmAE(e) {
      console.log(e)
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit === 1) {
            // 添加

            const { data: res } = await addSite(this.form)
            if (res.code === 1) {
              this.$message.success('添加成功')
              this.getList()
              this.cancelAE()
            } else {
              this.$message.error(res.message)
            }
          } else {
            // 编辑

            if (this.isEdit === true) {
              const { data: res } = await editSiteType(this.form)
              if (res.code === 1) {
                this.$message.success('编辑成功')
                this.cancelAE()
                this.getList()
              } else {
                this.$message.error(res.message)
              }
            } else {
              this.$message.info('没有修改任何数据')
            }
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../assets/exclamationmark.png') no-repeat;
}
</style>
