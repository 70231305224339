import { post } from '@/utils/request'

//获取站点列表
export function getSiteList(data) {
    return post('/edu/edu_site/siteList', data)
}
//添加站点
export function addSite(data) {
    return post('/edu/edu_site/addSite', data)
}
//站点回显
export function getInfo(data) {
    return post('/edu/edu_site/siteInfo', data)
}

//站点编辑
export function editSiteType(data) {
    return post('/edu/edu_site/editSite', data)
}
//站点删除
export function delSiteType(data) {
    return post('/edu/edu_site/delSite', data)
}
